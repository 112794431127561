import { Component, OnInit, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  @Output() values: EventEmitter<any>;
  constructor() {
    this.values =  new EventEmitter();
  }

  ngOnInit(): void {
  }
  onShowVlues() {
    this.values.emit();
  }

}