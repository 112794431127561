<div style="margin: 0;padding: 0;">
    <div class="banner-container">
        <div class="us">

        </div>
        <div class="banner">
            <div class="shadow"></div>

        </div>
    </div>
    <div class="us-content">
        <p id="title">
            {{ 'titleWhoAreWe' | translate }}
        </p>
        <div>
            <p>
                {{ 'introduction1' | translate }}
            </p>
            <p>
                {{ 'introduction2' | translate }}
            </p>

        </div>
    </div>

</div>

<!-- <a href="#miModal">Ver mas..</a> -->