import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { BannerComponent } from './components/banner/banner.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BriefcaseComponent } from './components/briefcase/briefcase.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxCarouselModule } from 'igniteui-angular';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { CookieLawModule } from 'angular2-cookie-law';
import { IgxIconModule } from 'igniteui-angular';
import { CoustumersComponent } from './components/coustumers/coustumers.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    BannerComponent,
    AboutUsComponent,
    BriefcaseComponent,
    ContactComponent,
    ProductDetailComponent,
    HomeComponent,
    OnlyNumberDirective,
    OnlyLettersDirective,
    AlphaNumericDirective,
    CoustumersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CookieLawModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    IgxIconModule,
    IgxCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

