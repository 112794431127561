<section>
    <h2>{{ 'titleCoustumers' | translate }}</h2>
    <div class="coustumers">
        <div class="coustomer" *ngFor="let item of coustumers">
            <div>
                <img [src]="dirCoustumers + '/' + item.img">
            </div>
            <p>
                {{ item.name }}
            </p>
        </div>
    </div>

</section>