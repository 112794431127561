<div class="header">
    <button> <img src="../../../assets/images/back.svg" loading="lazy" alt="back" (click)="onBack()"></button>
</div>
<section>
    <h2>{{ productService.title | translate }} <span *ngIf="productService.shortDescription"> ({{ productService.shortDescription | translate }}) </span> </h2>
    <div class="carousel-container">
        <igx-carousel #carousel [loop]="false" indicatorsOrientation="bottom" [maximumIndicatorsCount]="15" [navigation]="true">
            <igx-slide *ngFor=" let slide of slides; ">
                <div class="image-container">
                    <img [src]="slide ">
                </div>
            </igx-slide>
        </igx-carousel>
    </div>
    <div class="description ">
        <div class="info ">
            <div class="logo " *ngIf="productService.logo ">
                <img [src]="productService.logo ">
            </div>
            <div class="text ">
                <h3>{{ productService.descriptionTitle | translate }}</h3>
                <p *ngFor="let paragraph of paragraphs " [innerHTML]="paragraph"></p>
                <div *ngIf="productService.mainImage" class="mainImage">
                    <img [alt]="productService.title" [lazyLoad]="productService.mainImage | translate" [srcset]="productService.mainImage | translate  ">
                </div>
            </div>
            <div class="lists">
                <div class="functionality " *ngIf="functionalities.length >0" [ngClass]="{'other': productService.functionalities && productService.differences}">
                    <h3>{{ productService.titleFuncionalities | translate }}</h3>
                    <ul class="list-funcionalities">
                        <li *ngFor="let funcionality of functionalities " [innerHTML]="funcionality"></li>

                    </ul>
                </div>
                <div class="functionality " *ngIf="differences.length>0" [ngClass]="{'other': productService.functionalities && productService.differences}">
                    <h3>{{ 'valueAdded' | translate}}</h3>
                    <ul class="list-differences">
                        <li *ngFor="let difference of differences" [innerHTML]="difference"></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="productService.secondaryImage" class="secondImage">
        <img [alt]="productService.title" [lazyLoad]="productService.secondaryImage" [srcset]="productService.secondaryImage">
    </div>
    <div *ngIf="paragraphs2.length>0" class="secondDescription">
        <p *ngFor="let paragraph2 of paragraphs2 " [innerHTML]="paragraph2"></p>
    </div>
    <div *ngIf="arrangement.length> 0" class="arrangement">
        <h3>
            {{ 'titlePlan' | translate }}
        </h3>
        <div class="card-flow">
            <div class="card" *ngFor="let item of arrangement">
                <div class="card-header">
                    <div class="img-logo">
                        <img [src]="item.image" width="64px" alt="planes" *ngIf="item.image">
                    </div>
                    <p>
                        {{ item.title | translate }}
                    </p>
                </div>
                <div class="card-item">
                    <span class="concept">{{'titleInfrastructure' | translate}}:<br> </span>{{ item.infrastructure | translate }}
                </div>
                <div class="card-item">
                    <span class="concept">{{'titleImplementation' | translate}}: <br></span>{{ item.implementation | translate }}
                </div>
                <div class="card-item">
                    <span class="concept">{{'titleAcquisition'| translate}}: <br></span> {{ item.acquisition | translate }}
                </div>
            </div>
        </div>
    </div>
</section>