import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coustumers',
  templateUrl: './coustumers.component.html',
  styleUrls: ['./coustumers.component.scss']
})
export class CoustumersComponent implements OnInit {
  dirCoustumers = '../../../assets/coustomers';
  coustumers = [
    {
      name: 'Tracomex',
      img: 'tracomex.png'
    },
    {
      name: 'verint',
      img: 'verint.png'
    },
    {
      name: 'Cvidya',
      img: 'cvidya.png'
    },
    {
      name: 'INAH',
      img: 'INAH.png'
    },
    {
      name: 'LCT',
      img: 'lazaro-cardenas.png'
    },
    {
      name: 'TIMSA',
      img: 'timsa.png'
    },
    {
      name: 'TILH',
      img: 'tilh.png'
    }/* ,
    {
      name: 'INCORP',
      img: 'incorp.png'
    } */,
    {
      name: 'Aseguradora Patrimonial',
      img: 'ap.png'
    },
    {
      name: 'triplei',
      img: 'triplei.png'
    }
    ,
    {
      name: 'AdminiStrategia',
      img: 'administrategia.png'
    }
    ,
    {
      name: 'Ingredion',
      img: 'ingredion.png'
    }
    ,
    {
      name: 'Banco Sofitasa',
      img: 'sofitasa.jpg'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
