import { Injectable } from '@angular/core';
import { ProductsInt, ContactInt } from '../intefaces/products.inteface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  serviceProduct: ProductsInt =  null;
  private x: number;
  private y: number;
  private item: ProductsInt;
  constructor(private  http: HttpClient ) { }

  setStorage(item: ProductsInt, y: number) {
    let str: string = null;
    if ( item ){
      str = this.encrypt( JSON.stringify( item ));
      localStorage.setItem('656873', `${ str }|${y}`);
    }
    else {
      localStorage.setItem('656873', `${ null }|${y}`);
    }
  }

  getStorage(): boolean {
    const local = localStorage.getItem('656873');
    const values = local.split('|');
    const y = parseInt( values[1], 0);
    let item: any = null;
    if ( values[0] ) {
      item = this.decrypt( values[0] );
    }
    this.setY( parseInt(values[1], 0) );
    this.setItem( item );
    return this.serviceProduct === null;
  }
  getY(): number {
    return this.y;
  }

  setY( value: number ) {
    this.y = value;
  }

  setItem( value: ProductsInt ) {
    this.item = value;
  }

  getItem(): ProductsInt {
    return this.item;
  }

  cleanAll() {
    localStorage.clear();
  }

  encrypt( value ) {
    const encrypt = CryptoJS.AES.encrypt(value, 'encriptado').toString();
    return encrypt;
  }
  decrypt( value ) {
    const bytes  = CryptoJS.AES.decrypt(value, 'encriptado');
    const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decrypt;
  }

  register( data: ContactInt ) {
    const HEADERS = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
    .append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    const request = {
      headers: HEADERS,
      responseType: 'json',
      withCredentials: false,
      params: null
    };
    return this.http.post(`${ environment.urlApi }/register.php`, data, {
      responseType: 'json'
    });
  }
}
