<div class="menu-container">
    <div class="logo">
        <button id="menu" (click)="onShowMenu()">
            <img src="../../../assets/images/bars-solid.svg" srcset="./../../assets/images/bars-solid.svg" width="24px" alt="Menú" *ngIf="showMenu === false">
            <img src="../../../assets/images/close.svg" srcset="../../../assets/images/close.svg" width="24px" alt="Menú" *ngIf="showMenu === true">
        </button>
        <img src="../../../assets/images/Ehawksystems.png" srcset="../../../assets/images/Ehawksystems.png" alt="e-hawksystems">
        <div>
            <a href=""><img src="../../../assets/images/twitter-brands.svg" srcset="../../../assets/images/twitter-brands.svg" class="social" alt="twitter"></a>
            <a href=""><img src="../../../assets/images/webpfacebook-f-brands.svg" srcset="../../../assets/images/facebook-f-brands.svg" class="social" alt="facebook"></a>
        </div>
    </div>
    <nav class="class" [ngClass]="{
            'active': showMenu === true
          }">
        <a href="#home">{{ 'menuHome' | translate }}</a>
        <a href="#us">{{ 'menuAbout' | translate }}</a>
        <a href="#briefcase">{{ 'menuBriefcase' | translate }}</a>
        <a href="#contact">{{ 'menuContact' | translate }}</a>
        <div class="languages">
            <button (click)="changeLang('es')" *ngIf="langInUse==='en'">
                <img src="../../../assets/images/flagMexico.jpg" srcset="../../../assets/images/flagMexico.jpg" width="16px" alt="espanol">
            </button>
            <button (click)="changeLang('en')" *ngIf="langInUse==='es'">
                <img src="../../../assets/images/flagUS.png" srcset="../../../assets/images/flagUS.png" width="16px" alt="us">
            </button>
        </div>
    </nav>
</div>