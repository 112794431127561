import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showMenu = false;
  langInUse = '';
  constructor(private translate: TranslateService) {

    this.langInUse = translate.currentLang;
    console.log(
      translate.getBrowserCultureLang()
    );
    // this.langInUse =  translate.get()
   }

  ngOnInit(): void {
  }
  onShowMenu(){
  this.showMenu = !this.showMenu;
  }
  onClick(position: string){
    document.location.href = `#${position}`;
    setTimeout(() => {
      this.showMenu = !this.showMenu;
    }, 300);
  }
  changeLang( lang: string ) {
   this.translate.use( lang );
   this.langInUse = lang;
   if ( this.showMenu ) {
    this.showMenu = false;
   }
  }

}
