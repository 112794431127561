import { Component, OnInit } from '@angular/core';
import { ProductsInt } from '../../intefaces/products.inteface';
import { data } from '../../../assets/products/data';
import { GlobalsService } from '../../services/globals.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-briefcase',
  templateUrl: './briefcase.component.html',
  styleUrls: ['./briefcase.component.scss']
})
export class BriefcaseComponent implements OnInit {
  products: Array<ProductsInt> = data.products;

  services: Array<ProductsInt> =  data.services;
  constructor(
    private globals: GlobalsService,
    private router: Router
    ) { }
  ngOnInit(): void {
  }

  onService( item ) {
    const y = window.scrollY;
    this.globals.serviceProduct = item;
    this.globals.setY( y );
    this.router.navigate(['product']);
    this.globals.setStorage( item, y );
  }

}
