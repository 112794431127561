<div style="height: 60px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M0.00,49.98 C-79.57,-128.78 489.27,-52.78 57.56,-4.44 L77.88,1.48 L-1.69,156.41 Z" style="stroke: none; fill: #0B2747;
"></path></svg></div>
<section>
    <h2>{{ 'menuBriefcase' | translate }}</h2>
    <h3>{{ 'titleServices' | translate }}</h3>
    <div class="brifcase">
        <button class=" bubbles" *ngFor="let item of services" (click)="onService( item )">
            <div class="card-header">
                <div class="circle">
                    <img class="ng-lazyloaded" [lazyLoad]="item.logo" width="100%" height="100%" [srcset]="item.logo" alt="services">
                </div>
                <div style="margin: 10px; width: 100%;">
                    <h4>
                        {{ item.title | translate }}
                    </h4>
                </div>
            </div>

        </button>
    </div>
    <h3>{{ 'titlesProducts' | translate }}</h3>
    <div class="brifcase">
        <div class="card" *ngFor="let item of products">
            <div class="card-header">
                <div class="circle">
                    <img [lazyLoad]="item.logo" [srcset]="item.logo" class="ng-lazyloaded" alt="services" *ngIf="item.images.length > 0 ">
                </div>
                <div style="margin: 10px;">
                    <h4>
                        {{ ( item.shortDescription || item.title) | translate }}
                    </h4>
                </div>
            </div>
            <div class="card-body">
                <p class="description">
                    {{ item.description | translate | slice:0:200 }} <span *ngIf="item.description.length > 0">...</span>
                </p>
            </div>
            <div class="card-footer">
                <button (click)="onService( item )" *ngIf="item.functionalities">
                    {{ 'textLearnMore' | translate }}
                </button>
            </div>
        </div>
    </div>
</section>