<div style="height: 60px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M428.89,-2.45 C505.64,-0.48 507.34,-5.42 602.25,2.47 L508.46,42.92 L502.82,156.41 Z" style="stroke: none; fill: #0B2747;"></path></svg></div>
<section>
    <h2>{{ 'menuContact' | translate }}</h2>
    <div class="contact">
        <div class="map">
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.1582266806154!2d-99.22325718499145!3d19.534819386826378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21d30777da45b%3A0x7c8d97cba6d2bdd4!2sE-HAWK%20SYSTEMS%2C%20S.A.%20DE%20C.V.!5e0!3m2!1ses-419!2smx!4v1589302742371!5m2!1ses-419!2smx?force=lite"
                    class="map-content" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            <div class="map-info">
                <h3>{{ 'titleMap' | translate }}</h3>
                <p>
                    Calle Encino 49, Hab Valle de Los Pinos 1a. Secc, Tlalnepantla de Baz, Méx
                </p>
                <div class="links">
                    <div class="link">

                        <a href="https://api.whatsapp.com/send?phone=+525585309268" target="blank"><img src="../../../assets/images/whatsapp-brands.svg" width="36px" alt="whatsapp" srcset=""> <span>  (52)5585309268</span></a>
                    </div>
                    <div class="link">
                        <a href="tel:5585309268"><img src="../../../assets/images/phone-solid.svg" width="36px" alt="whatsapp" srcset=""> <span>  (52)5585309268</span></a>
                    </div>
                    <div class="link">
                        <a href="mailto: contacto@e-hawksystems.com"><img src="../../../assets/images/envelope-solid.svg" width="36px" alt="whatsapp" srcset=""> <span>contacto@e-hawksystmes.com</span></a>
                    </div>
                </div>

            </div>
        </div>
        <div class="form">
            <div class="form-content">
                <form #formContact="ngForm" (ngSubmit)="onSend(formContact)" novalidate>
                    <div class="form-title">
                        {{ 'titleForm' | translate }}
                    </div>
                    <div class="form-group">
                        <label for="nameContact">{{ 'labelName' | translate }}</label>
                        <input class="form-control" appOnlyLetters type="text" id="nameContact" name="nameContact" minlength="1" maxlength="50" [(ngModel)]="contact.nameContact" #nameContact="ngModel" nameContact required>
                        <div *ngIf="nameContact.invalid && (nameContact.dirty || nameContact.touched)" class="input-error">
                            <div *ngIf="nameContact.errors.required">{{ 'requireField' | translate: { value: titleName } }}</div>
                            <div *ngIf="nameContact.errors.minlength">{{ 'maxField' | translate: { value: 'titleName' } }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">{{ 'labelEmail' | translate }}</label>
                        <input class="form-control" appAlphaNumeric type="text" id="email" name="email" pattern="[\w-\+]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[a-z]{2,})$" minlength="1" maxlength="50" [(ngModel)]="contact.email" #email="ngModel" email required>
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="input-error">
                            <div *ngIf="email.errors.required">{{ 'requireField' | translate: { value: titlEmail } }}</div>
                            <div *ngIf="email.errors.minlength">{{ 'maxField' | translate: { value: titlEmail } }}</div>
                            <div *ngIf="email.errors.pattern">{{ 'formatEmail' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phoneNumber">{{ 'labelPhoneNumber' | translate }}</label>
                        <input class="form-control" appOnlyNumbers type="text" id="phoneNumber" name="phoneNumber" minlength="10" maxlength="10" [(ngModel)]="contact.phoneNumber" #phoneNumber="ngModel" phoneNumber required>
                        <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="input-error">
                            <div *ngIf="phoneNumber.errors.required">{{ 'requireField' | translate: { value: titlePhone } }}</div>
                            <div *ngIf="phoneNumber.errors.minlength">{{ 'maxField' | translate: { value: titlePhone } }}</div>
                        </div>
                    </div>
                    <div class="send">
                        <button type="submit" [disabled]="formContact.invalid || loading === true">{{ (loading ? 'sendingButton' : 'sendButton') | translate }}</button>
                        <div [ngClass]="{
                            'successMessage': response.success,
                            'input-error': !response.success
                            }" *ngIf="response.msjResponse">
                            {{ response.msjResponse }}
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</section>