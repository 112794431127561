<div style="height: 60px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M428.89,-2.45 C505.64,-0.48 507.34,-5.42 602.25,2.47 L508.46,42.92 L502.82,156.41 Z" style="stroke: none; fill: #0B2747;"></path></svg></div>
<section class="about-us">
    <h2>{{ 'menuAbout' | translate }}</h2>
    <div class="aboutus-content">
        <div class="devloper">
            <img [lazyLoad]="'assets/images/developer1.jpg'" alt="developer" srcset="assets/images/developer1.jpg">
        </div>
        <div class="aboutus-info">
            <div class="info">
                <p>{{ 'textUS1' | translate }}</p>
                <p>{{ 'textUS2' | translate }}</p>
                <p>{{ 'textUS3' | translate }}</p>
                <p>{{ 'textUS4' | translate }}</p>
                <p>{{ 'textUS5' | translate }}</p>
                <p>{{ 'textUS6' | translate }}</p>

            </div>
            <div class="cards-flow">
                <div class="card">
                    <div class="card-header">
                        {{ 'titleVision' | translate }}
                    </div>
                    <div class="card-body">
                        <p>
                            {{ 'textVision' | translate }}
                        </p>
                    </div>

                </div>
                <div class="card">
                    <div class="card-header">
                        {{ 'titleMission' | translate }}
                    </div>
                    <div class="card-body">
                        <p>
                            {{ 'textMission' | translate }}
                        </p>
                    </div>

                </div>
                <div class="card">
                    <div class="card-header">
                        {{ 'titlePhilosophy' | translate }}
                    </div>
                    <div class="card-body">
                        <p>
                            {{ 'textPhilosophy' | translate }}
                        </p>
                    </div>

                </div>
                <div class="card">
                    <div class="card-header">
                        {{ 'titleValues' | translate }}
                    </div>
                    <div class="card-body">
                        <p>
                            {{ 'textValues1' | translate }}
                        </p>
                        <p>
                            {{ 'textValues2' | translate }}
                        </p>

                    </div>
                    <!-- <div>
                        <button (click)="onShowVlues()" class="buttonMore"> Ver más..</button>
                    </div> -->

                </div>


            </div>
        </div>
    </div>
</section>