import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { ProductsInt, DischargeInt, DischargeDetailInt } from '../../intefaces/products.inteface';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  productService: ProductsInt;
  paragraphs: Array<string> = [];
  paragraphs2: Array<string> = [];
  slides: Array<string> = [];
  arrangement: Array<DischargeDetailInt> = [];
  functionalities: Array<string> = [];
  differences: Array<string> = [];
  private y = 0;
  constructor( private globals: GlobalsService, private router: Router, private translateServ: TranslateService) {
    window.scrollTo(0, 0);
    if ( globals.getStorage() ) {
      this.globals.serviceProduct = globals.getItem();
    }
    this.y = this.globals.getY();
    this.productService = globals.serviceProduct;
    this.slides = this.productService.images;
    if ( this.productService.discharge ){
      this.getDischarge(this.productService.discharge);
    }
    this.getParagraphs( this.productService.description );
    this.getFuncionalities( this.productService.functionalities );
    this.getDifferences( this.productService.differences );
    this.getParagraphs( this.productService.secondDescription, false );
  }

  ngOnInit(): void {

  }

  onBack() {
    this.router.navigate(['']);
    this.globals.serviceProduct = null;
    setTimeout(() => {
      window.scrollTo(0, this.y);
      this.globals.setY( null );
      this.globals.cleanAll();
    }, 100);
  }
  private getParagraphs( p: string, main = true ) {
    if ( p ){
      this.translateServ.stream( p ).subscribe(
        res => {
          if ( main ){
            this.paragraphs = res.split('. ');
          }
          else {
            this.paragraphs2 = res.split('. ');
          }
        }
      );
    }
  }
  
  private getFuncionalities(f: string) {
    if ( f ){
      this.translateServ.stream( f ).subscribe(
        res => {
          this.functionalities = res.split('|');
        }
      );
    }
  }

  private getDifferences( d: string ) {
    if ( d ){
      this.translateServ.stream( d ).subscribe(
        res => {
          this.differences = res.split('|');
        }
      );
    }
  }

  private getDischarge( discharge: DischargeInt ) {
    this.arrangement.push({
      title: 'titleConcepts',
      infrastructure: 'titleInfrastructure',
      implementation: 'titleImplementation',
      acquisition: 'titleAcquisition'
    });
    if ( discharge.cloud ) {
      discharge.cloud.image = '../../../assets/images/cloud.svg';
      this.arrangement.push(discharge.cloud);
    }

    if ( discharge.local ) {
      discharge.local.image = '../../../assets/images/local.svg';
      this.arrangement.push(discharge.local);
    }

    if ( discharge.manufacture ) {
      discharge.manufacture.image = '../../../assets/images/maquila.svg';
      this.arrangement.push(discharge.manufacture);
    }
  }
}
