import { Component, OnInit } from '@angular/core';
import { ContactInt, ErrorInt, ResponseInt } from '../../intefaces/products.inteface';
import { NgForm } from '@angular/forms';
import { GlobalsService } from '../../services/globals.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact: ContactInt = {
    nameContact: null,
    email: null,
    phoneNumber: null
  };
  titleName: string;
  titlEmail: string;
  titlePhone: string;
  response: ResponseInt = {
    success: false,
    msjResponse: ''
  };
  loading = false;
  constructor(
    private globals: GlobalsService,
    private traslateServ: TranslateService
  ) { }

  ngOnInit(): void {
    this.traslateServ.stream('labelName').subscribe(rs => this.titleName = rs);
    this.traslateServ.stream('labelEmail').subscribe(rs => this.titlEmail = rs);
    this.traslateServ.stream('labelPhoneNumber').subscribe(rs => this.titlePhone = rs);
  }

  onSend(form: NgForm) {
    let r: any;
    this.loading = true;
    this.globals.register( this.contact ).subscribe(
      response => r = response as ResponseInit,
      (error: ErrorInt) => {
        this.loading = false;
        this.response = {
          success: false,
          msjResponse: error.error.message
        };
      },
      () => {
        this.loading = false;
        this.response = r;
        if (this.response.success){
          setTimeout(() => {
            this.contact = {
               nameContact: null,
               email: null,
               phoneNumber: null
            };
            this.response = {
              msjResponse: null,
              success: false
            };
          }, 1000);
        }
      }
    );
  }

}
