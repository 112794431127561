import { DataInt } from 'src/app/intefaces/products.inteface';

export const data: DataInt = {
    services: [
        {
            title: 'titleDeveloper',
            description: 'descriptionDeveloper',
            descriptionTitle: 'titleService',
            titleFuncionalities: 'functionalities',
            logo: '../../../assets/services/sistemaMedida.jpeg',
            mainImage: '../../../assets/services/Desarrollo04.png',
            images: [
                '../../../assets/services/Desarrollo01.png',
                '../../../assets/services/Desarrollo03.png',
                '../../../assets/services/Desarrollo02.png',
                '../../../assets/services/development2.png',
                '../../../assets/services/development1.png'
            ],
            functionalities: 'funcionalitiesListDeveloper',
        },
        {
            title: 'titleOutsorcing',
            description: 'descriptionOutsorcing',
            descriptionTitle: 'titleService',
            titleFuncionalities: 'functionalitiesOutsorcing',
            logo: '../../../assets/services/Out_Sourcing_01.jpg',
            mainImage: '../../../assets/services/Out_Sourcing_04.jpg',
            images: [
                '../../../assets/services/outsourcing2.png',
                '../../../assets/services/Out_Sourcing_02.jpg',
                '../../../assets/services/Out_Sourcing_03.jpg',
                '../../../assets/services/outsourcing1.png'
            ],
            functionalities: 'funcionalitiesListOutsorcing',
            differences: 'valuesOutsoncing'
        },
        {
            title: 'titleApps',
            descriptionTitle: 'titleService',
            titleFuncionalities: 'functionalities',
            description: 'descriptionApp',
            differences: 'valuesApps',
            logo: '../../../assets/services/Apps01.png',
            mainImage: '../../../assets/services/Apps06.jpg',
            images: [
                '../../../assets/services/Apps02.png',
                '../../../assets/services/Apps03.png',
                '../../../assets/services/Apps04.png',
                '../../../assets/services/Apps05.png'
            ]

        },
        {
            title: 'titleBigData',
            descriptionTitle: 'titleService',
            description: 'descriptionBigData',
            differences: 'valuesBigData',
            logo: '../../../assets/services/Big_Data_01.jpg',
            images: [
                '../../../assets/services/Big_Data_02.jpg',
                '../../../assets/services/Big_Data_03.jpg',
                '../../../assets/services/data1.png',
                '../../../assets/services/data2.png'
            ]
        },
        {
            title: 'Business Intelligence',
            descriptionTitle: 'titleService',
            description: 'descriptionBI',
            logo: '../../../assets/services/Business_Intelligence_01.jpg',
            images: [
                '../../../assets/services/Business_Intelligence_02.jpg',
                '../../../assets/services/Business_Intelligence_03.jpg',
                '../../../assets/services/Business_Intelligence_04.jpg'
            ]
        }

    ],
    products: [
        {
            title: 'titleSPCT',
            shortDescription: 'shortTitleSPCT',
            logo: '../../../assets/products/SPCT/SPCTLogo.png',
            mainImage: '../../../assets/products/SPCT/SPC_AllViews.png',
            titleFuncionalities: 'functionalities',
            description: 'descriptionSPCT',
            descriptionTitle: 'titleProduct',
            images: [
                '../../../assets/products/SPCT/Termnal_Planning_and_Control_07.png',
                '../../../assets/products/SPCT/Termnal_Planning_and_Control_04.jpg',
                /*  '../../../assets/products/SPCT/Termnal_Planning_and_Control_00.jpg',
                '../../../assets/products/SPCT/Termnal_Planning_and_Control_01.jpg', */
                '../../../assets/products/SPCT/Termnal_Planning_and_Control_02.jpg',
                // '../../../assets/products/SPCT/Termnal_Planning_and_Control_03.jpg',
                '../../../assets/products/SPCT/Termnal_Planning_and_Control_06.jpg'
            ],
            functionalities: 'funcionalitiesSPCT',
        },
        {
            title: 'titleEnomina',
            shortDescription: 'shortDescriptionEnom',
            logo: '../../../assets/products/enomina/logoEnomina.jpeg',
            mainImage: 'enominaIamge',
            secondaryImage: '../../../assets/products/enomina/enominaAll.jpeg',
            secondDescription: 'secondDescriptionEnomina',
            descriptionTitle: 'titleProduct',
            titleFuncionalities: 'functionalities',
            description: 'descriptionEnomina',
            images: [
                '../../../assets/products/enomina/Nomina_04.jpg',
                '../../../assets/products/enomina/Nomina_01.jpg',
                '../../../assets/products/enomina/Nomina_02.png',
                '../../../assets/products/enomina/Nomina_03.jpg',
                '../../../assets/products/enomina/Nomina_05.jpg'
            ],
            functionalities: 'funcionalitiesEnomina',
            differences: 'valuesEnomina',
            discharge: {
                cloud: {
                    title: 'titleDischargeCloud',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                },
                local: {
                    title: 'Local',
                    infrastructure: 'titleInfrestructureClient',
                    implementation: 'implementationRemote2',
                    acquisition: 'titleAcquisition2'
                },
                manufacture: {
                    title: 'titleDischargeManofacture',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                }
            }
        },
        {
            title: 'titleSA3',
            shortDescription: 'eh-SA^3',
            logo: '../../../assets/products/enomina/Nomina_01.jpg',
            mainImage: '../../../assets/products/enomina/Nomina_02.png',
            descriptionTitle: 'titleProduct',
            titleFuncionalities: 'functionalities',
            description: 'descriptionSA3',
            images: [
                '../../../assets/products/enomina/Nomina_03.jpg',
                '../../../assets/products/enomina/Nomina_04.jpg',
                '../../../assets/products/enomina/Nomina_05.jpg',
            ],
            functionalities: null,
            differences: null,
            discharge: {
                cloud: {
                    title: 'titleDischargeCloud',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                },
                local: {
                    title: 'Local',
                    infrastructure: 'titleInfrestructureClient',
                    implementation: 'implementationRemote2',
                    acquisition: 'titleAcquisition2'
                },
                manufacture: {
                    title: 'titleDischargeManofacture',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                }
            }
        },
        {
            title: 'titleSDL',
            shortDescription: 'eh-SLDA',
            logo: '../../../assets/products/SLD/madrina_01.png',
            mainImage: '../../../assets/products/SLD/madrina_01.png',
            descriptionTitle: 'titleProduct',
            titleFuncionalities: 'functionalities',
            description: 'descriptionSDL',
            images: [
                '../../../assets/products/SLD/madrina_01.png',
                '../../../assets/products/SLD/madrina_02.png'
            ],
            functionalities: null,
            differences: null,
            discharge: {
                cloud: {
                    title: 'titleDischargeCloud',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                },
                local: {
                    title: 'Local',
                    infrastructure: 'titleInfrestructureClient',
                    implementation: 'implementationRemote2',
                    acquisition: 'titleAcquisition2'
                },
                manufacture: {
                    title: 'titleDischargeManofacture',
                    infrastructure: 'E-hawk',
                    implementation: 'implementationRemote1',
                    acquisition: 'titleAcquisition1'
                }
            }
        }

    ]
};
