import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appOnlyNumbers]'
})
export class OnlyNumberDirective {

  constructor() { }
  @HostListener('keypress') onkeypress(e) {
    const event = e || window.event;
    if (event) {
      return this.onlyNumbers(event);
    }
  }

  onlyNumbers(event: any) {
    const exp = /[\d]/g;
    const inputChar = String.fromCharCode(event.charCode);
    return exp.test(inputChar);
  }

}
