import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appOnlyLetters]'
})
export class OnlyLettersDirective {

  constructor() { }
  @HostListener('keypress') onkeypress(e) {
    const event = e || window.event;
    if (event) {
      return this.onlyLetters(event);
    }
  }

  onlyLetters(event: any) {
    const exp = /[A-Za-z ]/g;
    const inputChar = String.fromCharCode(event.charCode);
    return exp.test(inputChar);
  }

}
